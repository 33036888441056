import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  Filter,
  id,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import { Observable } from 'rxjs';
import {
  CreateCTMSOwnedUserGroupRequest,
  FilterChoiceOption,
  GroupBasicInfoViewModel,
  GroupCarrierLaneItemViewModel,
  GroupPaginationInvoicingItemViewModel,
  GroupPaginationItemViewModel,
  UserGroupViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class CTMSUserGroupService extends BaseApiService {
  constructor() {
    super(`user.v1.ctmsusergroup`);
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<GroupPaginationItemViewModel>>(
      `${this.url}paginated-ctms-owned-group`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  enterpriseByFeaturePagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(`${this.url}paginated-enterprises-by-feature`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  userGroupPaginationCount(filters: Filter[]): Observable<number> {
    return this.http.post<number>(`${this.url}paginated-count`, {
      filterDefinitions: filters,
    });
  }

  userGroupExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  allPaginationInvoice(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<GroupPaginationInvoicingItemViewModel>> {
    return this.http.post<
      PaginationResponse<GroupPaginationInvoicingItemViewModel>
    >(`${this.url}paginated-for-invoicing`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  allPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  carrierLanePagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<GroupCarrierLaneItemViewModel>> {
    return this.http.post<PaginationResponse<GroupCarrierLaneItemViewModel>>(
      `${this.url}paginated-for-carrierlane`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  carrierLanePaginationFilters() {
    return this.http.get(`${this.url}paginated-for-carrierlane-filters`);
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  createGroup(request: CreateCTMSOwnedUserGroupRequest) {
    return this.http.post<UserGroupViewModel>(
      `${this.url}create-ctms-owned-group`,
      request,
    );
  }

  getBasicInfo(groupId: id) {
    return this.http.get<GroupBasicInfoViewModel>(
      `${this.url}detail/basic-info/${groupId}`,
    );
  }
}
