import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import { ngClassInput } from '@cca-common/cdk';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import DOMPurify from 'dompurify';
import { ShowMoreLessComponent } from '../show-more-less';
import { HtmlViewerComponent } from './html-viewer.component';

@Component({
  selector: 'cca-rich-text-viewer',
  standalone: true,
  imports: [
    NgClass,
    TranslocoModule,
    ShowMoreLessComponent,
    HtmlViewerComponent,
  ],
  templateUrl: './rich-text-viewer.component.html',
  styleUrl: './rich-text-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('richTextViewer')],
})
export class RichTextViewerComponent {
  titleLabel = input<string>();
  text = input<string | undefined | null>(null);
  styling = input<ngClassInput>('');
  large = input<boolean>(false);

  safeText = computed(() => {
    const text = this.text();
    if (!text) {
      return '';
    }

    // note for some reason, quil removes all <p><br/></p> and replaces them with empty <p></p>
    // This makes where we would render this html be missing those line breaks
    // therefore we are replacing them here once more
    return DOMPurify.sanitize(text.replaceAll('<p></p>', '<p></br></p>'), {
      RETURN_TRUSTED_TYPE: true,
    });
  });

  isOverflow = signal(false);
  isExpanded = false;

  onHtmlContainerDimensionsChange(event: {
    scrollHeight: number;
    clientHeight: number;
  }) {
    this.isOverflow.set(event.scrollHeight > event.clientHeight);
  }
}
