import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'cca-html-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
  template: `<div class="html-container" [innerHTML]="html()"></div>`,
  styles: `
    p,
    ol,
    li,
    ul {
      margin: 0;
    }
  `,
})
export class HtmlViewerComponent {
  private element = inject(ElementRef);

  html = input.required<TrustedHTML | SafeHtml | string | null | undefined>();

  containerDimensionsChange = output<{
    scrollHeight: number;
    clientHeight: number;
  }>();

  constructor() {
    effect(() => {
      const html = this.html();

      if (html) {
        this.updateContainerDimensions();
      }
    });
  }

  private updateContainerDimensions() {
    const container =
      this.element.nativeElement.shadowRoot.querySelector('.html-container');
    this.containerDimensionsChange.emit({
      scrollHeight: container.scrollHeight,
      clientHeight: container.clientHeight,
    });
  }
}
