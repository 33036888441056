/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = CustomerBillsNotPaid  2 = CustomerNotAFit  3 = CustomerFailedCreditCheck  100 = SupplierBad  101 = SupplierDueDiligenceNotPassed  102 = SupplierFailedCreditCheck  103 = FreightForwarder  104 = SupplierNoCurrentBusiness  200 = Prospect  300 = CTMSOnly  1000 = DueDiligenceDateReached
 */
export enum DeactivationReasonStatus {
  Undefined = 0,
  CustomerBillsNotPaid = 1,
  CustomerNotAFit = 2,
  CustomerFailedCreditCheck = 3,
  SupplierBad = 100,
  SupplierDueDiligenceNotPassed = 101,
  SupplierFailedCreditCheck = 102,
  FreightForwarder = 103,
  SupplierNoCurrentBusiness = 104,
  Prospect = 200,
  CTMSOnly = 300,
  DueDiligenceDateReached = 1000,
}
