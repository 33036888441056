/**
 *   0 = Undefined  1 = CarrierContact  2 = ChainCargoOperator  3 = PickupLocationContact  4 = DeliveryLocationContact  5 = Driver  6 = PickupTimeSlotContact  7 = DeliveryTimeSlotContact  8 = Booker  9 = Sales  10 = Tender
 */
export enum ContactPersonType {
  Undefined = 0,
  CarrierContact = 1,
  ChainCargoOperator = 2,
  PickupLocationContact = 3,
  DeliveryLocationContact = 4,
  Driver = 5,
  PickupTimeSlotContact = 6,
  DeliveryTimeSlotContact = 7,
  Booker = 8,
  Sales = 9,
  Tender = 10,
  ShipperContact = 11,
}
